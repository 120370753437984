import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './floodings.jpeg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Flood Defence <br /><small>Lammas Lands</small>
          </h2>
          <p>
            <strong>Significant flooding</strong> has occurred in Godalming in 1968, 1990, 2000, 2013 and 2020. These events are
            getting closer together with climate change. During the 2013 flood, <strong>84 properties</strong> in the Meadrow and Catteshall area
            were affected and homeowners evacuated. <strong>Residents were out of their homes for many months.</strong>
          </p>
          <p>
            The Lammas Lands are recognised as a <strong>vital part of the flood protection measures of the town</strong> and this <strong>cannot be compromised</strong>.
          </p>
          <p>
            To quote MP Jeremy Hunt in 2014 - <span style={{ fontStyle: 'italic', fontWeight: 600 }}>"Just been to see heartbroken residents in Godalming affected by floods. Clear that Lammas Lands needs 2 perform better role in flood defence"</span>
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
