import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './image.png';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageleft/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Management Plan" />
              <p>
                The <a href="/management-plan-2017.pdf" target="_blank">Lammas Lands Management plan</a> offers the following detail regarding both the function and importance of the functional floodplain,
                it even lists it as it's <strong>primary objective.</strong>
              </p>
              <p>
                "The Lammas Lands are <strong>part of the floodplain of the River Wey</strong>. At times of flood, water
                from the Wey floods runs onto the meadows from the river and either exits into the Hell
                Ditch marking the northern edge of the main field system or passes down the meadows
                to the east before exiting to the river further down."
              </p>
              <p>
                "The slightly higher land around
                Lammas Lands is largely built up and the meadows form a <strong>vital part of the flood relief
                measures</strong> helping to <strong>prevent or alleviate flooding of these properties.</strong>"
              </p>
              <p>
                "It is therefore
                important that <strong>nothing is done to prevent or obstruct the free flow of water across the
                meadows</strong>, and that the <strong>open nature of the site is maintained</strong>"
              </p>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
