import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './wbc.jpg';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageleft/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Planning Policy" />
              <p>
                The <a href="/local-plan.pdf" target="_blank">local plan for Waverley</a> has the following to say about flood risk management (Policy CC4). This
                is <strong>not speific to the Lammas Lands</strong>, but section c should be of specific interest when talking about any development on the functional floodplain.
              </p>
              <p>
                Development must be located, designed and laid out to ensure that it is safe;
                that the <strong>risk from flooding is minimised</strong> whilst <strong>not increasing the risk of flooding </strong>
                elsewhere; and that residual risks are safely managed. In locations identified
                as being at risk of flooding, planning permission will only be granted, or land
                allocated for development, where it can be demonstrated that:
              </p>
              <p>
                <strong>a.</strong> where sequential and exceptions tests have been undertaken and passed,
                any development that takes place where there is a risk of flooding will need
                to <strong>ensure that flood mitigation measures</strong>, including a site specific flood
                evacuation plan, are integrated into the design both on-site and off-site, to
                <strong>minimise the risk to property and life should flooding occur</strong>;
              </p>
              <p>
                <strong>b.</strong> through a sequential approach, it is located in the <strong>lowest appropriate flood risk
                location</strong> in accordance with the NPPF and the Waverley Strategic Flood Risk
                Assessment (SFRA)
              </p>
              <p>
                <strong>c.</strong> It would <strong>not constrain the natural function of the functional floodplain</strong>, either by
                impeding flood flow or reducing storage capacity.
              </p>
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
