import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/Image';
import ProtectedViewImage from './Flooding.jpg';
import BlockWrapper, {
  ContentWrapper,
  ImageWrapper,
} from 'containers/shared/imageright/humanityBlock.style';

const HumanityBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="socialFundraising">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col text-container" {...col}>
            <ContentWrapper>
              <Heading content="Other Flood Defences" />
              <p>
                The <strong>£4.5m Godalming Flood Alleviation Scheme</strong> was carried out by Mackley working as part of Team Van Oord and on behalf of a partnership comprising the Environment Agency, Surrey County Council, Waverley Borough Council, Godalming Town Council, Thames Water and the local Godalming Flood Group.
              </p>
              <p>
                The scheme, which reduces the risk of flooding to 90 properties in the town, was officially unveiled on 11 October by Emma Howard Boyd, chair of the Environment Agency, and Jeremy Hunt South West Surrey MP.
              </p>
              <p>
                Aditionally Two pumping stations were also installed to pump out a combined total of 84 litres per second.  The pumps catch water fed by the surface water drainage system and ground water, and will pump water back to Hell Ditch during times of high river levels.
              </p>
            </ContentWrapper>
          </Box>
          <Box className="col image-container" {...col}>
            <ImageWrapper>
              <GatsbyImage
                src={ ProtectedViewImage }
                alt="Charity Landing"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default HumanityBlock;
