import React from 'react';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BlockWrapper, {
  CounterWrapper,
  CounterItem,
} from 'containers/shared/counterblock/milestoneBlock.style';

const MilestoneBlock = () => {
  return (
    <Container id="milestone" width="1260px">
      <BlockWrapper>
        <h2>Public support for future management</h2>
        <CounterWrapper>
          <CounterItem>
            <Heading as="h3" content="87.7%" />
            <Text content="Floodplain management" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="87.7%" />
            <Text content="Habitat Management" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="83.6%" />
            <Text content="Volunteer Group" />
          </CounterItem>
          <CounterItem>
            <Heading as="h3" content="91.7%" />
            <Text content="Cattle Grazing" />
          </CounterItem>
        </CounterWrapper>
      </BlockWrapper>
    </Container>
  );
};

export default MilestoneBlock;
